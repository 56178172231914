<script setup lang="ts">
import { type BreadcrumbItem } from "~/types/common";

const props = withDefaults(
  defineProps<{
    items: BreadcrumbItem[] | null;
  }>(),
  {
    items: null,
  },
);
const { t } = useI18n();
</script>

<template>
  <div class="flex items-center breadcrumb bg-white px-2 py-[15px] xl:py-4.5">
    <ul
      v-if="Array.isArray(props.items)"
      class="!list-none !flex !m-0 !p-0 text-brand-headline text-xs xl:text-16"
    >
      <!-- Home page -->
      <li class="!flex items-center">
        <NuxtLink to="/" class="unstyled text-brand-headline flex items-center">
          <i class="m-icon-home text-xs xl:text-base mr-0 md:mr-2.5"></i>
          <span class="hidden md:block">{{ t("home") }}</span>
        </NuxtLink>
      </li>
      <!-- Breadcrumb items -->
      <li
        v-for="(item, index) in props.items"
        :key="index"
        class="!flex items-center"
      >
        <i
          class="m-icon-arrow-breadcrumb-right-blk text-sm ml-[9px] mr-[8px]"
          :class="{
            'hidden md:block': index > 1, // Show items from md and up
          }"
        ></i>
        <!-- Parent items (with URL) -->
        <template v-if="item.url">
          <NuxtLink
            :to="item.url"
            class="unstyled text-brand-headline whitespace-nowrap limited-width text-ellipsis overflow-hidden"
            :class="{
              'hidden md:block': index > 1, // Show items from md and up
            }"
          >
            {{ item.label }}
          </NuxtLink>
        </template>
        <!-- Current page (without URL) -->
        <template v-else>
          <span
            class="text-brand-search font-semibold whitespace-nowrap limited-width text-ellipsis overflow-hidden"
            :class="{
              'hidden md:block': index > 1, // Show items from md and up
            }"
          >
            {{ item.label }}
          </span>
        </template>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.limited-width {
  max-width: calc(
    100vw - 16px /* Padding X */ - 100px /* Home page + icon */ - 62px
      /* 2 separators (assuming 3 total levels) */
  );
}
</style>
